import {
  SET_PRELOADING,
  TOGGLE_MENU,
  SET_DESKTOP_BACKGROUND,
  TOGGLE_ABOUT_MODAL,
  TOGGLE_CONTACT_US_MODAL,
  SET_SLEEPING_MODE,
  TOGGLE_TEAM_MODAL,
  SET_GLOBAL_ZINDEX,
  TOGGLE_OUR_SERVICES_MODAL,
  TOGGLE_TRASH_MODAL,
  TOGGLE_CLOCK_MODAL,
  TOGGLE_MINESWEEPER_MODAL,
  TOGGLE_PAINT_MODAL,
  TOGGLE_ANTIVIRUS_MODAL,
  SET_CLOSE_ALL_MODALS,
  TOGGLE_SEARCH_MODAL,
  TOGGLE_FOOTER,
  TOGGLE_PROJECTS_MODAL,
  TOGGLE_WEB3COMPASS_MODAL,
  TOGGLE_THRUPENNY_MODAL,
  TOGGLE_COYOTE_MODAL,
  TOGGLE_CLOVERCOIN_MODAL,
  TOGGLE_GOGOFINANCE_MODAL,
  TOGGLE_MOMENTO_MODAL,
  TOGGLE_JUSTYOURS_MODAL,
  TOGGLE_YOP_MODAL,
  TOGGLE_MORTAL_METACITIES,
  TOGGLE_KONSTA_MODAL,
  TOGGLE_PARTNERS_MODAL,
  TOGGLE_NFTLOOTS_MODAL,
  TOGGLE_CHUBCAY_MODAL,
  TOGGLE_AUTHENTICO_MODAL,
  TOGGLE_SYNCDAO_MODAL,
  TOGGLE_OURGOALS_MODAL,
  TOGGLE_DOME_MODAL,
  TOGGLE_SILKSWAP_MODAL,
  TOGGLE_CRENFT_MODAL,
  TOGGLE_CRESTAKING_MODAL,
  TOGGLE_TORTUGABET_MODAL,
  TOGGLE_LASERWAR_MODAL,
  TOGGLE_RABBIT_MODAL,
  SET_MAXIMIZE_CONTACTS_MODAL,
  SET_MAXIMIZE_PROJECTS_MODAL,
  SET_MAXIMIZE_COMPASS_PROJECT_MODAL,
  SET_MAXIMIZE_YOP_PROJECT_MODAL,
  SET_MAXIMIZE_THRUPENNY_PROJECT_MODAL,
  SET_MAXIMIZE_MORTAL_PROJECT_MODAL,
  SET_MAXIMIZE_MOMENTO_PROJECT_MODAL,
  SET_MAXIMIZE_KONSTA_PROJECT_MODAL,
  SET_MAXIMIZE_JUSTYOURS_PROJECT_MODAL,
  SET_MAXIMIZE_GOGO_PROJECT_MODAL,
  SET_MAXIMIZE_COYOTE_PROJECT_MODAL,
  SET_MAXIMIZE_CLOVERCOIN_PROJECT_MODAL,
  SET_MAXIMIZE_ABOUTUS_MODAL,
  SET_MAXIMIZE_MINESWEEPER_MODAL,
  SET_MAXIMIZE_PARTNERS_MODAL,
  SET_MAXIMIZE_OURSERVICES_MODAL,
  SET_MAXIMIZE_PAINT_MODAL,
  SET_MAXIMIZE_TEAMS_MODAL,
  SET_MAXIMIZE_TRASH_MODAL,
  SET_MAXIMIZE_CLOCK_MODAL,
  SET_UNMAXIMIZE_ALL_MODALS,
  SET_MAXIMIZE_NFTLOOTS_MODAL,
  SET_MAXIMIZE_CHUBCAY_MODAL,
  SET_MAXIMIZE_AUTHENTICO_MODAL,
  SET_MAXIMIZE_SYNCDAO_MODAL,
  SET_MAXIMIZE_OURGOALS_MODAL,
  SET_MAXIMIZE_DOME_MODAL,
  SET_MAXIMIZE_SILKSWAP_MODAL,
  SET_MAXIMIZE_CRENFT_MODAL,
  SET_MAXIMIZE_CRESTAKING_MODAL,
  SET_MAXIMIZE_TORTUGABET_MODAL,
  SET_MAXIMIZE_LASERWAR_MODAL,
  SET_MAXIMIZE_RABBIT_MODAL,
} from './types';

export const initialState = {
  preLoading: true,
  activeBg: '1',
  globalZindex: 4,
  isMenuOpen: false,
  isSleepingModeOn: false,
  isMaximized: false,
  isFooterToggled: false,
  modals: {
    isAboutUsModalOpen: false,
    isContactUsModalOpen: false,
    isTeamModalOpen: false,
    isOurServicesModalOpen: false,
    isTrashModalOpen: false,
    isClockModalOpen: false,
    isMinesweeperModalOpen: false,
    isPaintModalOpen: false,
    isAntivirusModalOpen: false,
    isSearchModalOpen: false,
    isProjectsModalOpen: false,
    isWeb3CompassModalOpen: false,
    isThrupennyModalOpen: false,
    isCoyoteModalOpen: false,
    isClovercoinModalOpen: false,
    isGogoFinanceModalOpen: false,
    isMomentoModalOpen: false,
    isJustYoursModalOpen: false,
    isYopModalOpen: false,
    isMortalMetacitiesModalOpen: false,
    isKonstaModalOpen: false,
    isPartnersModalOpen: false,
    isNftLootsModalOpen: false,
    isChubcayModalOpen: false,
    isAuthenticoModalOpen: false,
    isSyncDaoModalOpen: false,
    isOurGoalsModalOpen: false,
    isDomeModalOpen: false,
    isSilkSwapModalOpen: false,
    isCreNftModalOpen: false,
    isCreStakingModalOpen: false,
    isTortugaBetModalOpen: false,
    isLaserWarModalOpen: false,
    isRabbitModalOpen: false,
  },
  maximizedModals: {
    isContactsModalMaximized: false,
    isAboutUsModalMaximized: false,
    isMinesweeperModalMaximized: false,
    isPartnersModalMaximized: false,
    isOurServicesModalMaximized: false,
    isPaintModalMaximized: false,
    isTeamsModalMaximized: false,
    isTrashModalMaximized: false,
    isClockModalMaximized: false,
    isProjectsModalMaximized: false,
    isCompassProjectModalMaximized: false,
    isYOPProjectModalMaximized: false,
    isThrupennyProjectModalMaximized: false,
    isMortalProjectModalMaximized: false,
    isMomentoProjectModalMaximized: false,
    isKonstaProjectModalMaximized: false,
    isJustYoursProjectModalMaximized: false,
    isGogoProjectModalMaximized: false,
    isCoyoteProjectModalMaximized: false,
    isClovercoinProjectModalMaximized: false,
    isNftsLootsProjectModalMaximized: false,
    isChubcayProjectModalMaximized: false,
    isAuthenticoProjectModalMaximized: false,
    isSyndDaoProjectModalMaximized: false,
    isOurGoalsProjectModalMaximized: false,
    isDomeProjectModalMaximized: false,
    isSilkSwapProjectModalMaximized: false,
    isCreNftProjectModalMaximized: false,
    isCreStakingProjectModalMaximized: false,
    isTortugaBetProjectModalMaximized: false,
    isLaserWarProjectModalMaximized: false,
    isProjectModalMaximized: false,
    isRabbitModalMaximized: false,
  },
};

export default function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case SET_PRELOADING: {
      return {
        ...state,
        preLoading: payload,
      };
    }
    case TOGGLE_MENU: {
      return {
        ...state,
        isMenuOpen: payload,
      };
    }
    case SET_DESKTOP_BACKGROUND: {
      return {
        ...state,
        activeBg: payload,
      };
    }

    case TOGGLE_ABOUT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isAboutUsModalOpen: payload,
        },
      };
    }
    case TOGGLE_CONTACT_US_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isContactUsModalOpen: payload,
        },
      };
    }
    case SET_SLEEPING_MODE: {
      return {
        ...state,
        isSleepingModeOn: payload,
      };
    }
    case TOGGLE_PAINT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isPaintModalOpen: payload,
        },
      };
    }
    case TOGGLE_TEAM_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isTeamModalOpen: payload,
        },
      };
    }
    case SET_GLOBAL_ZINDEX: {
      const zIndex = state.globalZindex;

      return {
        ...state,
        globalZindex: zIndex + 1,
      };
    }
    case TOGGLE_OUR_SERVICES_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isOurServicesModalOpen: payload,
        },
      };
    }
    case TOGGLE_TRASH_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isTrashModalOpen: payload,
        },
      };
    }
    case TOGGLE_CLOCK_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isClockModalOpen: payload,
        },
      };
    }
    case TOGGLE_MINESWEEPER_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isMinesweeperModalOpen: payload,
        },
      };
    }
    case TOGGLE_ANTIVIRUS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isAntivirusModalOpen: payload,
        },
      };
    }
    case SET_CLOSE_ALL_MODALS: {
      const { modals } = state;
      const newModals = {};
      for (const key in modals) {
        if (modals[key]) {
          newModals[key] = false;
        }
      }

      return {
        ...state,
        modals: {
          ...state.modals,
          ...newModals,
        },
      };
    }
    case SET_UNMAXIMIZE_ALL_MODALS: {
      const { maximizedModals } = state;
      const newUnMaximizedModals = {};
      for (const key in maximizedModals) {
        if (maximizedModals[key]) {
          newUnMaximizedModals[key] = false;
        }
      }

      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          ...newUnMaximizedModals,
        },
      };
    }
    case SET_MAXIMIZE_CONTACTS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isContactsModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_ABOUTUS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isAboutUsModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_MINESWEEPER_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isMinesweeperModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_PARTNERS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isPartnersModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_OURSERVICES_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isOurServicesModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_PAINT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isPaintModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_TEAMS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isTeamsModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_TRASH_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isTrashModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_CLOCK_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isClockModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_PROJECTS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isProjectsModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_COMPASS_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isCompassProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_YOP_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isYOPProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_THRUPENNY_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isThrupennyProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_LASERWAR_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isLaserWarProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_MORTAL_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isMortalProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_MOMENTO_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isMomentoProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_KONSTA_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isKonstaProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_JUSTYOURS_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isJustYoursProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_GOGO_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isGogoProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_COYOTE_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isCoyoteProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_NFTLOOTS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isNftsLootsProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_CHUBCAY_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isChubcayProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_AUTHENTICO_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isAuthenticoProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_SYNCDAO_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isSyncDaoProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_OURGOALS_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isOurGoalsProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_DOME_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isDomeProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_SILKSWAP_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isSilkSwapProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_CRENFT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isCreNftProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_CRESTAKING_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isCreStakingProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_TORTUGABET_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isTortugaBetProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_CLOVERCOIN_PROJECT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isClovercoinProjectModalMaximized: payload,
        },
      };
    }
    case SET_MAXIMIZE_RABBIT_MODAL: {
      return {
        ...state,
        maximizedModals: {
          ...state.maximizedModals,
          isRabbitModalMaximized: payload,
        },
      };
    }
    case TOGGLE_SEARCH_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isSearchModalOpen: payload,
        },
      };
    }
    case TOGGLE_FOOTER: {
      return {
        ...state,
        isFooterToggled: payload,
      };
    }
    case TOGGLE_PARTNERS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isPartnersModalOpen: payload,
        },
      };
    }
    case TOGGLE_PROJECTS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isProjectsModalOpen: payload,
        },
      };
    }
    case TOGGLE_WEB3COMPASS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isWeb3CompassModalOpen: payload,
        },
      };
    }
    case TOGGLE_NFTLOOTS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isNftLootsModalOpen: payload,
        },
      };
    }

    case TOGGLE_LASERWAR_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isLaserWarModalOpen: payload,
        },
      };
    }

    case TOGGLE_CHUBCAY_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isChubcayModalOpen: payload,
        },
      };
    }
    case TOGGLE_AUTHENTICO_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isAuthenticoModalOpen: payload,
        },
      };
    }
    case TOGGLE_SYNCDAO_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isSyncDaoModalOpen: payload,
        },
      };
    }
    case TOGGLE_OURGOALS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isOurGoalsModalOpen: payload,
        },
      };
    }
    case TOGGLE_DOME_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isDomeModalOpen: payload,
        },
      };
    }
    case TOGGLE_SILKSWAP_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isSilkSwapModalOpen: payload,
        },
      };
    }
    case TOGGLE_CRENFT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isCreNftModalOpen: payload,
        },
      };
    }
    case TOGGLE_CRESTAKING_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isCreStakingModalOpen: payload,
        },
      };
    }
    case TOGGLE_TORTUGABET_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isTortugaBetModalOpen: payload,
        },
      };
    }
    case TOGGLE_THRUPENNY_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isThrupennyModalOpen: payload,
        },
      };
    }
    case TOGGLE_COYOTE_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isCoyoteModalOpen: payload,
        },
      };
    }
    case TOGGLE_CLOVERCOIN_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isClovercoinModalOpen: payload,
        },
      };
    }
    case TOGGLE_GOGOFINANCE_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isGogoFinanceModalOpen: payload,
        },
      };
    }
    case TOGGLE_MOMENTO_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isMomentoModalOpen: payload,
        },
      };
    }
    case TOGGLE_JUSTYOURS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isJustYoursModalOpen: payload,
        },
      };
    }
    case TOGGLE_YOP_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isYopModalOpen: payload,
        },
      };
    }
    case TOGGLE_MORTAL_METACITIES: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isMortalMetacitiesModalOpen: payload,
        },
      };
    }
    case TOGGLE_KONSTA_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isKonstaModalOpen: payload,
        },
      };
    }
    case TOGGLE_RABBIT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isRabbitModalOpen: payload,
        },
      };
    }
    default:
      return state;
  }
}
