import React, { useState, useEffect, memo } from 'react';

import { useLocation } from '@gatsbyjs/reach-router';

import { Button, IconWrapper, Text } from './StyledStartButton';
import WindowsIcon from '../../../../assets/images/icons/windows.svg';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

function StartButton({ isOpen, toggleMenu }) {
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    toggleMenu(!isOpen);
  };

  const handleOutSideClick = () => {
    if (isOpen) {
      toggleMenu(false);
    }
  };

  const ref = useOnClickOutside(handleOutSideClick);

  useEffect(() => {
    if (location.pathname.length > 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  return (
    <Button
      onClick={handleClick}
      ref={ref}
      disabled={isDisabled}
      isOpen={isOpen}
    >
      <IconWrapper>
        <WindowsIcon />
      </IconWrapper>
      <Text>Start</Text>
    </Button>
  );
}

export default memo(StartButton);
